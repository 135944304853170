@media print
{   
    @page {    
      size: A4 !important;
      margin: 10mm !important;
    }
    
    body {
    /* A5 dimensions */
    height: 210mm;
    width: 148.5mm;
    margin: 0;
    }
}