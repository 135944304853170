.form__choose {
  font-size: 12px;
}

.form__search {
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
}
.bg_img_none {
  background-image: none !important;
}

.form__waiting_list {
  text-align: right;
  width: 50% !important;
}

.form__search_list ul {
  max-height: 43vh !important;
  overflow: auto !important;
  margin-top: -16px !important;
  border-radius: 0;
}
.form__search_list ul li {
  line-height: 1;
  cursor: pointer;
}

.form__invalid {
  position: absolute;
  top: 100%;
}
.form__search_list {
  position: absolute !important;
  z-index: 2;
}
.form__button {
  min-width: 80px;
}

.checkbox__container {
  display: flex;
  justify-content: flex-start;
}

.tooltips {
  cursor: pointer;
  position: relative;
  padding: 0px;
  margin-bottom: 6px;
}

.tooltips:hover .tooltip__element {
  /* height: 12px;
  width: 12px; */
  transition: 600ms;
}

.tooltips:hover .tooltip__info {
  display: block;
}

.tooltip__info::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
}
.tooltip__element {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid black;
  margin-top: 5px;
  font-size: 13px;
}
.tooltip__info {
  display: none;
  position: absolute;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  color: white;
  background-color: #203f87;
  z-index: 3;
  font-size: 12px;
  min-width: 170px;
}
.tooltip__info p {
  padding: 5px;
}

.tooltip__right .tooltip__info {
  top: 50%;
  transform: translate(0, -50%);
  left: 100%;
  margin-left: 5px;
}
