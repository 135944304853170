.fc {
  height: 88vh;
}

.hover-link {
  background-color: white;
  height: 44px;
  width:50px;
  color: 203f87;
  padding: 5px;
  border-radius: 2px;
  margin: 5px;
  border: 1px solid #203f87;
}
.hover-link:hover {
  background-color: #203f87;
  color:white;
}

.cal-link-container {
  margin-top: 10px;
}

.cal-popup-container {
  position:fixed; 
  background-color: white; 
  /* height: 44px;
  width:150px; */
  z-index:999;
  font-size:11.5px;
  border-radius: 3px;
  padding:2px;
  color:black;
  padding: 10px;
}